import tinymce from 'tinymce/tinymce';

import 'tinymce/icons/default';

import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';

import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';

import _ from 'underscore';

import qq from 'fine-uploader';
import fine_uploader_tmpl from './fine-uploader-tinymce.tmpl';

if (!$('#qq-tinymce-template').length) {
    const tmpldiv = $('<div/>');
    tmpldiv.attr('id', 'qq-tinymce-template');
    tmpldiv.attr('style', 'display: none !important');
    tmpldiv.html(fine_uploader_tmpl());
    $('body').append(tmpldiv);
}

export default {
    full($elem) {
        if ($elem.attr('data-tinymce-id') === undefined) {
            $elem.attr('data-tinymce-id', _.uniqueId('tinymce'));
        }

        tinymce.init({
            selector: `[data-tinymce-id="${$elem.attr('data-tinymce-id')}"]`,
            height: 300,
            menubar: false,
            statusbar: false,
            toolbar: 'undo redo | styleselect | bold italic | bullist numlist outdent indent | link myimage',
            style_formats: [
                {title: 'Standaard', inline: 'p', format: 'p'},
                {title: 'Kop 1', inline: 'h1', format: 'h1'},
                {title: 'Kop 2', inline: 'h2', format: 'h2'},
                {title: 'Kop 3', inline: 'h3', format: 'h3'},
            ],
            valid_elements: 'p,b,i,em,strong,a[href],img[src|alt|title|width|height],h1,h2,h3,ul,ol,li',
            plugins: 'paste,link,image',
            paste_word_valid_elements: 'p,b,i,em,strong,a[href],img[src],h1,h2,h3,ul,ol,li',
            setup(editor) {
                // hack to save TinyMCEs to textarea continuously. This is necessary for e.g. the modals, where form values are copied to a different form
                editor.on('change', () => {
                    editor.save();
                    $elem.trigger('change');
                });

                const id = _.uniqueId('fineuploader');
                $elem.after(`<div id='${id}'></div>`);
                const uploader = new qq.FineUploader({
                    element: document.getElementById(id),
                    template: 'qq-tinymce-template',
                    multiple: true,
                    disableCancelForFormUploads: true,
                    messages: {
                        sizeError: '{file} is te groot, de maximale bestandsgrootte is {sizeLimit}.',
                    },
                    validation: {
                        sizeLimit: 1000 * 1000 * 250, // apx. 250MB
                    },
                    request: {
                        endpoint: $elem.data('upload-url'),
                    },
                    callbacks: {
                        onComplete(id, name, json) {
                            console.log('upload complete', id, name, json);
                            editor.execCommand('mceInsertContent', false, `<img src='${json.url}' alt='' />`);
                        },
                        onError(id, name, errorReason, xhr) {
                            toastr.error(`Kan bestand ${name} niet uploaden`);
                        },
                    },
                });

                editor.ui.registry.addButton('myimage', {
                    type: 'button',
                    title: 'Afbeelding',
                    icon: 'image',
                    id: 'myimage',
                    onAction() {
                        $(`#${id}`).find("[name='qqfile']").click();
                    },
                });
            },
            file_picker_callback(callback, value, meta) {
                // Provide image and alt text for the image dialog
                if (meta.filetype == 'image') {
                    callback('myimage.jpg');
                }
            },
        });
    },
    simple($elem) {
        if ($elem.attr('data-tinymce-id') === undefined) {
            $elem.attr('data-tinymce-id', _.uniqueId('tinymce'));
        }

        tinymce.init({
            selector: `[data-tinymce-id="${$elem.attr('data-tinymce-id')}"]`,
            height: 300,
            menubar: false,
            statusbar: false,
            toolbar: 'bold italic',
            valid_elements: 'p,b,i,em,strong',
            plugins: 'paste',
            paste_word_valid_elements: 'p,b,i,em,strong',
            setup(editor) {
                // hack to save TinyMCEs to textarea continuously. This is necessary for e.g. the modals, where form values are copied to a different form
                editor.on('change', () => {
                    editor.save();
                    $elem.trigger('change');
                });
            },
        });
    },
    empty($elem) {
        if ($elem.attr('data-tinymce-id') === undefined) {
            $elem.attr('data-tinymce-id', _.uniqueId('tinymce'));
        }

        tinymce.init({
            selector: `[data-tinymce-id="${$elem.attr('data-tinymce-id')}"]`,
            height: 300,
            menubar: false,
            statusbar: false,
            valid_elements: 'p,b,i,em,strong',
            plugins: 'paste,autoresize',
            paste_word_valid_elements: 'p,b,i,em,strong',
            setup(editor) {
                // hack to save TinyMCEs to textarea continuously. This is necessary for e.g. the modals, where form values are copied to a different form
                editor.on('change', () => {
                    editor.save();
                    $elem.trigger('change');
                });
            },
        });
    },
};
