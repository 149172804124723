module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var id = _.uniqueId("filerow"); 
__p+='\n<a href="'+
((__t=( DownloadUrl ))==null?'':__t)+
'" data-toggle="modal" data-target="#show-file-modal-'+
((__t=(UploadedFileId))==null?'':__t)+
'" class="file-img-container">\n	<div class="file-img">\n		<img data-selector="thumbnail" src="'+
((__t=( ThumbnailUrl == null ? IconUrl : ThumbnailUrl ))==null?'':__t)+
'"/>\n	</div>\n</a>\n<div class="file-content selectable">\n	<input type="hidden" name="UploadedFiles.Index" value="'+
((__t=( id ))==null?'':_.escape(__t))+
'" />\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].DownloadUrl" value="'+
((__t=( DownloadUrl ))==null?'':_.escape(__t))+
'" />\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].ThumbnailUrl" value="'+
((__t=( ThumbnailUrl ))==null?'':_.escape(__t))+
'" />\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].PreviewUrl" value="'+
((__t=( PreviewUrl ))==null?'':_.escape(__t))+
'" />\n\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].Title" value="'+
((__t=( Title ))==null?'':_.escape(__t))+
'" />\n	<input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].Description" value="'+
((__t=( Description ))==null?'':_.escape(__t))+
'" />\n\n	<div class="file-title">\n		<a href="'+
((__t=( DownloadUrl ))==null?'':__t)+
'" data-toggle="modal" data-target="#show-file-modal-'+
((__t=(UploadedFileId))==null?'':__t)+
'" class="file-title inline-value" >'+
((__t=( Title ))==null?'':_.escape(__t))+
'</a>\n		<input type="text" name="Title" value="'+
((__t=( Title ))==null?'':_.escape(__t))+
'" class="form-control inline-edit" data-inputfor="Title" />\n	</div>\n	<div class="file-description">\n		<span class="inline-value">\n			';
 if(Description != null && Description.length > 150) { 
__p+='\n				'+
((__t=( Description.substring(0, 147) ))==null?'':_.escape(__t))+
'...\n			';
 } else { 
__p+='\n				'+
((__t=( Description ))==null?'':_.escape(__t))+
'\n			';
 } 
__p+='\n		</span>\n		<textarea rows="3" class="form-control inline-edit" data-inputfor="Description">'+
((__t=(Description))==null?'':_.escape(__t))+
'</textarea>\n	</div>\n	<div class="file-actions">\n	    ';
 if(!Removing) { 
__p+='\n	        ';
 if(Selected) { 
__p+='\n                <button class="btn btn-link inline-value" data-selector="select-file-button">deselecteren</button>\n	        ';
 } else { 
__p+='\n                <button class="btn btn-link inline-value" data-selector="select-file-button">selecteren</button>\n	        ';
 } 
__p+='\n            <button class="btn btn-link inline-value" data-selector="edit-file-button">bewerken</button>\n            <button class="btn btn-link inline-edit" data-selector="save-file-button">opslaan</button>\n            <button class="btn btn-link btn-remove inline-value" data-selector="try-delete-file-button">verwijderen</button>\n\n        ';
 } else { 
__p+='\n            <button class="btn btn-link inline-value" data-selector="cancel-delete-file-button">annuleren</button>\n            <button class="btn btn-danger inline-value" data-selector="delete-file-button">verwijder</button>\n        ';
 } 
__p+='\n	</div>\n</div>\n'+
((__t=( fileModalPartial() ))==null?'':__t)+
'';
}
return __p;
};
