module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

	/* This modal should probably be kept in sync with the modal in the razor view */

__p+='\n\n<div class="modal" id="show-file-modal-'+
((__t=(UploadedFileId))==null?'':__t)+
'" tabindex="-1">\n	<div class="modal-dialog modal-xl">\n		<div class="modal-content">\n			<div class="modal-header">\n				<button type="button" class="close" data-dismiss="modal">\n					<span>&times;</span>\n				</button>\n				<h4 class="modal-title" id="myModalLabel1">Bestand preview</h4>\n			</div>\n			<div class="modal-body">\n				<div class="row">\n					<div class="col-lg-4">\n						<img src="'+
((__t=(PreviewUrl))==null?'':__t)+
'" style="max-width: 100%"/>\n					</div>\n					<div class="col-lg-8">\n						<h1>'+
((__t=(Title))==null?'':__t)+
'</h1>\n						<p style="white-space: pre-line">\n							'+
((__t=( Description ))==null?'':__t)+
'\n						</p>\n					</div>\n				</div>\n			</div>\n			<div class="modal-footer">\n				<button type="button" class="btn btn-cancel" data-dismiss="modal">Annuleren</button>\n				<a class="btn btn-download" href="'+
((__t=(DownloadUrl))==null?'':__t)+
'">Downloaden</a>\n			</div>\n		</div>\n	</div>\n</div>';
}
return __p;
};
