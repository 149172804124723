module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var id = _.uniqueId("aanvraagfile"); 
__p+='\n<div class="file-img">\n    <img data-selector="thumbnail" src="'+
((__t=( ThumbnailUrl == null ? IconUrl : ThumbnailUrl ))==null?'':__t)+
'"/>\n</div>\n<div class="file-content">\n    <div class="file-title">\n        <input type="hidden" name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].DownloadUrl" value="'+
((__t=( DownloadUrl ))==null?'':_.escape(__t))+
'" data-inputfor="DownloadUrl" />\n        <input type="text" name="Title" value="'+
((__t=( Title ))==null?'':__t)+
'" class="form-control" data-inputfor="Title" />\n    </div>\n    <div class="file-description">\n        <textarea name="UploadedFiles['+
((__t=( id ))==null?'':_.escape(__t))+
'].Description" class="form-control" data-inputfor="Description"/>'+
((__t=( Description ))==null?'':__t)+
'</textarea>\n    </div>\n    <div class="file-actions-pending">\n        <progress class="progress file-progress" value="0" max="100">0%</progress>\n        <button class="btn btn-link inline-edit" data-selector="save-file-button" style="display: none">Opslaan</button>\n    </div>\n</div>';
}
return __p;
};
