import pikaday from 'pikaday';
import moment from 'moment';

moment.locale('nl');

(function () {
    function dateValid(date) {
        const d = new Date(date[4], date[3] - 1, date[2], date[1], date[0]);
        return (
            d &&
            d.getFullYear() == date[4] &&
            d.getMonth() + 1 == date[3] &&
            d.getDate() == date[2] &&
            d.getHours() == date[1] &&
            d.getMinutes() == date[0]
        );
    }

    function pad(n, width, z) {
        z = z || '0';
        n += '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    const dayNames = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const dayNamesShort = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];
    const monthNames = [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
    ];

    function updateSpans(picker, date) {
        const d = new Date(date[4], date[3] - 1, date[2], date[1], date[0]);

        picker.find("[data-selector='dayName']").text(dayNames[d.getDay()]);
        picker.find("[data-selector='day']").text(d.getDate());
        picker.find("[data-selector='monthName']").text(monthNames[d.getMonth()]);
        picker.find("[data-selector='year']").text(d.getFullYear());
        picker.find("[data-selector='hour']").text(d.getHours());
        picker.find("[data-selector='minute']").text(pad(d.getMinutes(), 2));
    }

    function updateInputs(picker, date) {
        const d = new Date(date[4], date[3] - 1, date[2], date[1], date[0]);

        picker.find("input[data-selector='day']").val(d.getDate());
        picker.find("input[data-selector='month']").val(d.getMonth() + 1);
        picker.find("input[data-selector='year']").val(d.getFullYear());

        picker.find("input[data-selector='hour']").val(d.getHours());
        picker.find("input[data-selector='minute']").val(pad(d.getMinutes(), 2));
    }

    function template(date, id) {
        const hour = date.getHours();
        const minute = pad(date.getMinutes(), 2);

        const day = date.getDate();
        const dayName = dayNames[date.getDay()];
        const month = date.getMonth() + 1;
        const monthName = monthNames[date.getMonth()];
        const year = date.getFullYear();

        if (!id) id = _.uniqueId('datepicker');

        return (
            `<div class="datetimepicker" id="${id}">` +
            '<div class="datepicker-inputs">' +
            `<input class="date-input form-control" type="text" data-selector="day" data-validate-ignore autocomplete="off" placeholder="${day}"/><i>/</i>` +
            `<input class="date-input form-control" type="text" data-selector="month" data-validate-ignore autocomplete="off" placeholder="${month}"/><i>/</i>` +
            `<input class="date-input form-control large" type="text" data-selector="year" data-validate-ignore autocomplete="off" placeholder="${year}"/><i class="small">om</i>` +
            `<input class="date-input form-control" type="text" data-selector="hour" data-validate-ignore autocomplete="off" placeholder="${hour}"/><i>:</i>` +
            `<input class="date-input form-control" type="text" data-selector="minute" data-validate-ignore autocomplete="off" placeholder="${minute}"/>` +
            '</div>' +
            "<button type='button' class='btn btn-calendar' data-selector='datepicker-button'><i class='icon-calendar'></i></button>" +
            '<span class="datepicker-result">' +
            `<span data-selector="dayName" class="date-label">${dayName}</span> ` +
            `<span data-selector="day" class="date-label">${day}</span> ` +
            `<span data-selector="monthName" class="date-label">${monthName}</span>, ` +
            `<span data-selector="year" class="date-label">${year}</span> om ` +
            `<span data-selector="hour" class="date-label">${hour}</span>:` +
            `<span data-selector="minute" class="date-label">${minute}</span>` +
            '</span>' +
            '</div>'
        );
    }

    function getInputs(picker) {
        const dayInp = picker.find("input[data-selector='day']");
        const monthInp = picker.find("input[data-selector='month']");
        const yearInp = picker.find("input[data-selector='year']");
        const hourInp = picker.find("input[data-selector='hour']");
        const minuteInp = picker.find("input[data-selector='minute']");

        let found = false;
        let x = 0;
        let monthRaw = monthInp.val();
        if (monthRaw.length > 0) {
            for (let i = 0; i < 12; i++) {
                if (monthNames[i].indexOf(monthRaw) == 0) {
                    if (found) {
                        found = false; // not unique, so not found
                        break;
                    }

                    found = true;
                    x = i + 1;
                }
            }
        }
        if (found) monthRaw = x;

        const dayVal = parseInt(dayInp.val() || dayInp.attr('placeholder'));
        const monthVal = parseInt(monthRaw || monthInp.attr('placeholder'));
        let yearVal = parseInt(yearInp.val() || yearInp.attr('placeholder'));

        const hourVal = parseInt(hourInp.val() || hourInp.attr('placeholder'));
        const minuteVal = parseInt(minuteInp.val() || minuteInp.attr('placeholder'));

        yearVal = yearVal < 100 ? yearVal + 2000 : yearVal;

        return [minuteVal, hourVal, dayVal, monthVal, yearVal];
    }

    function updateOriginalInput($originalinput, date) {
        $originalinput.val(`${date[2]}-${date[3]}-${date[4]} ${date[1]}:${pad(date[0], 2)}`);
        $originalinput.change();
    }

    $.fn.datetimepicker = function (initDate, changeCb) {
        const $originalinput = $(this);
        const momentParsed = moment($originalinput.val());
        const initialDate = initDate || momentParsed.isValid() ? momentParsed.toDate() : new Date();

        const picker = $(template(initialDate, $originalinput.data('id')));
        $originalinput.after(picker);
        $originalinput.hide();

        if (changeCb) changeCb(initialDate);

        picker.find("[data-selector='datepicker-button']").each((ix, elem) => {
            const p = new pikaday({
                onSelect(date) {
                    const prev = getInputs(picker);

                    const minute = prev[0];
                    const hour = prev[1];
                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();

                    updateOriginalInput($originalinput, [minute, hour, day, month, year]);
                    updateSpans(picker, [minute, hour, day, month, year]);
                    updateInputs(picker, [minute, hour, day, month, year]);

                    if (changeCb) changeCb(new Date(year, month - 1, day, hour, minute));

                    $(this.el).hide();
                },
                i18n: {
                    previousMonth: 'Vorige maand',
                    nextMonth: 'Volgende maand',
                    months: monthNames,
                    weekdays: dayNames,
                    weekdaysShort: dayNamesShort,
                },
                keyboardInput: false,
            });
            elem.parentNode.insertBefore(p.el, elem.nextSibling);

            $(p.el).hide();

            $(elem).click(() => {
                $(p.el).toggle();
            });
        });

        const date = getInputs(picker);
        updateOriginalInput($originalinput, date);

        picker
            .find("input[data-selector='hour']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date) || !/^[0-9]+$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    updateOriginalInput($originalinput, date);
                    if (changeCb) changeCb(new Date(date[4], date[3] - 1, date[2], date[1], date[0]));
                    $this.val(date[1]);
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const date = getInputs(picker);
                const curVal = date[1];
                const maxVal = 23;
                if (key == 38) {
                    // up arrow
                    $this.val(curVal + 1 > maxVal ? maxVal : curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    $this.val(curVal - 1 < 0 ? 0 : curVal - 1);
                    $this.change();
                }
            });

        picker
            .find("input[data-selector='minute']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date) || !/^[0-9][0-9]+$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    updateOriginalInput($originalinput, date);
                    if (changeCb) changeCb(new Date(date[4], date[3] - 1, date[2], date[1], date[0]));
                    $this.val(pad(date[0], 2));
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const date = getInputs(picker);
                const curVal = date[0];
                const maxVal = 59;

                let newVal = null;
                if (key == 38) {
                    // up arrow
                    newVal = curVal + 1 > maxVal ? maxVal : curVal + 1;
                    $this.val(pad(newVal, 2));
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    newVal = curVal - 1 < 0 ? 0 : curVal - 1;
                    $this.val(pad(newVal, 2));
                    $this.change();
                }
            });

        picker
            .find("input[data-selector='day']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date) || !/^[0-9]+$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    updateOriginalInput($originalinput, date);
                    if (changeCb) changeCb(new Date(date[4], date[3] - 1, date[2], date[1], date[0]));
                    $this.val(date[2]);
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const date = getInputs(picker);
                const curVal = date[2];
                const maxVal = new Date(date[4], date[3], 0).getDate();
                if (key == 38) {
                    // up arrow
                    $this.val(curVal + 1 > maxVal ? maxVal : curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    $this.val(curVal - 1 < 1 ? 1 : curVal - 1);
                    $this.change();
                }
            });

        picker
            .find("input[data-selector='month']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date)) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    updateOriginalInput($originalinput, date);
                    if (changeCb) changeCb(new Date(date[4], date[3] - 1, date[2], date[1], date[0]));
                    $this.val(date[3]);
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const curVal = getInputs(picker)[3];
                if (key == 38) {
                    // up arrow
                    $this.val(curVal + 1 > 12 ? 12 : curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    $this.val(curVal - 1 < 1 ? 1 : curVal - 1);
                    $this.change();
                }
            });

        // inp is the input object, yr is a two-digit year value
        function setYearVal(inp, yr) {
            inp.val(pad(yr, 2));
        }

        picker
            .find("input[data-selector='year']")
            .change(function () {
                const $this = $(this);

                const date = getInputs(picker);
                if (!dateValid(date) || !/^[0-9]+$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    updateSpans(picker, date);
                    setYearVal($this, date[4] - 2000);
                    updateOriginalInput($originalinput, date);
                    if (changeCb) changeCb(new Date(date[4], date[3] - 1, date[2], date[1], date[0]));
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const curVal = getInputs(picker)[4] - 2000;
                if (key == 38) {
                    // up arrow
                    setYearVal($this, curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    setYearVal($this, curVal - 1);
                    $this.change();
                }
            });
    };
})();
