import moment from 'moment';

moment.locale('nl');

(function () {
    function pad(n, width, z) {
        z = z || '0';
        n += '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    function dateFromArray(arr) {
        const week = arr[0];
        const year = arr[1];

        if (week < 1 || week > 53) {
            return;
        }

        const date = moment({year, month: 1, day: 1});

        // go to first monday, then set week number
        while (date.day() != 1) date.add(1, 'days');

        date.isoWeek(week);

        return date;
    }

    function updateSpans(picker, d) {
        const fromDate = d;
        const tillDate = moment(d).add(6, 'days');

        let res = '';
        if (fromDate.year() != tillDate.year()) {
            res = `${fromDate.format('D MMMM YYYY')} t/m ${tillDate.format('D MMMM YYYY')}`;
        } else if (fromDate.month() != tillDate.month()) {
            res = `${fromDate.format('D MMMM')} t/m ${tillDate.format('D MMMM YYYY')}`;
        } else {
            res = `${fromDate.format('D')} t/m ${tillDate.format('D MMMM YYYY')}`;
        }

        picker.find('.datepicker-result').text(res);
    }

    function template(date, id) {
        const week = date.isoWeek();
        const year = date.year();

        const fromDay = date.date();
        const fromMonth = date.format('MMMM');

        const tillDate = moment(date);
        tillDate.add(6, 'days');

        const tillDay = tillDate.date();
        const tillMonth = tillDate.format('MMMM');

        return (
            `<div class="datepicker" id="${id}">` +
            '<div class="datepicker-inputs">' +
            `<input class="date-input form-control" type="text" data-validate-ignore autocomplete="off" data-selector="week" placeholder="${week}"/><i>/</i>` +
            `<input class="date-input form-control large" type="text" data-validate-ignore autocomplete="off" data-selector="year" placeholder="${year}"/>` +
            '</div>' +
            '<span class="datepicker-result"></span>' +
            '</div>'
        );
    }

    function getInputs(picker) {
        const weekInp = picker.find("input[data-selector='week']");
        const yearInp = picker.find("input[data-selector='year']");

        const weekVal = parseInt(weekInp.val()) || parseInt(weekInp.attr('placeholder'));
        let yearVal = parseInt(yearInp.val()) || parseInt(yearInp.attr('placeholder'));

        yearVal = yearVal < 100 ? yearVal + 2000 : yearVal;

        return dateFromArray([weekVal, yearVal]);
    }

    function updateOriginalInput($originalinput, date) {
        const str = date.format('YYYY-MM-DD');

        $originalinput.val(str);
        $originalinput.change();
    }

    $.fn.weekpicker = function (initDate, changeCb) {
        // initDate should be of format YYYY-MM-dd (ISO8601)
        const $originalinput = $(this);
        const specifiedDate = $originalinput.val() ? moment($originalinput.val()) : null;
        const initialDate = initDate || specifiedDate || moment();

        const picker = $(template(initialDate, $originalinput.data('id')));
        $originalinput.after(picker);
        $originalinput.hide();

        const date = getInputs(picker);
        updateSpans(picker, date);
        updateOriginalInput($originalinput, date);

        picker
            .find("input[data-selector='week']")
            .change(function () {
                const $this = $(this);

                if (!/^[0-9]([0-9])?$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    const date = getInputs(picker);
                    updateSpans(picker, date);
                    updateOriginalInput($originalinput, date);

                    if (changeCb) changeCb(date.toDate());

                    $this.val(date.isoWeek());
                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const date = getInputs(picker);
                const curVal = date.isoWeek();
                const maxVal = date.isoWeeksInYear();
                if (key == 38) {
                    // up arrow
                    $this.val(curVal + 1 > maxVal ? maxVal : curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    $this.val(curVal - 1 < 1 ? 1 : curVal - 1);
                    $this.change();
                }
            });

        picker
            .find("input[data-selector='year']")
            .change(function () {
                const $this = $(this);

                if (!/^[0-9][0-9][0-9][0-9]$/.test($this.val())) {
                    $this.addClass('date-error');
                    picker.find('.datepicker-result').hide();
                } else {
                    const date = getInputs(picker);
                    updateSpans(picker, date);
                    $this.val(date.format('YYYY'));
                    updateOriginalInput($originalinput, date);

                    if (changeCb) changeCb(date.toDate());

                    $this.removeClass('date-error');
                    picker.find('.datepicker-result').show();
                }
            })
            .on('keydown', function (e) {
                const key = e.keyCode;
                const $this = $(this);
                const curVal = getInputs(picker).year();
                if (key == 38) {
                    // up arrow
                    $this.val(curVal + 1);
                    $this.change();
                } else if (key == 40) {
                    // down arrow
                    $this.val(curVal - 1);
                    $this.change();
                }
            });
    };
})();
