import toastr from 'toastr';
import parseDecimalNumber from 'parse-decimal-number';
import numeral from './mynumeral.js';

import './datepicker';
import './datetimepicker';
import './weekpicker';
import './colorpicker';

require('jquery-validation');
import './custom-validators';

import tinyMCE from './mytinymce/mytinymce.js';

import filelist from './file-list/file-list.js';
import preventLeave from './preventleave.js';

import template_newfile_row from './file-list/newfile_row.tmpl';
import template_file_row from './file-list/file_row.tmpl';

import chat from './chat/chat';

$("input[type='datetime']:not([data-datepicker-ignore])").each((ix, elem) => {
    $(elem).datetimepicker();
});

$("input[type='date']:not([data-datepicker-ignore])").each((ix, elem) => {
    $(elem).datepicker();
});

$('input[data-weekpicker]').each((ix, elem) => {
    $(elem).weekpicker();
});

$('form[action]')
    .not('[data-validate]')
    .not('[data-nodisable]')
    .submit(function () {
        return $(this).find('input[type=submit], button').attr('disabled', 'disabled');
    });

$('[data-collapse-hide]').click(function () {
    $(this).hide();
});

if (typeof Storage !== 'undefined') {
    $('form[data-localstorage]').each(function (ix, elem) {
        const form = $(this);
        const localStorageKey = form.data('localstorage');

        form.find("input[type='text'][data-store]").each((inpIx, inpElem) => {
            const $inpElem = $(inpElem);
            const key = `${localStorageKey}/${$inpElem.data('store')}`;

            if (localStorage[key]) {
                $inpElem.val(localStorage[key]);
            }

            $inpElem.change(() => {
                localStorage.setItem(key, $inpElem.val());
            });
        });

        form.find('textarea[data-store]').each((inpIx, inpElem) => {
            const $inpElem = $(inpElem);
            const key = `${localStorageKey}/${$inpElem.data('store')}`;

            if (localStorage[key]) {
                $inpElem.val(localStorage[key]);
            }

            $inpElem.change(() => {
                localStorage.setItem(key, $inpElem.val());
            });
        });
    });
}

_.defer(() => {
    $('[data-chat-baseurl]').each((ix, elem) => {
        const $elem = $(elem);
        chat($elem.data('chat-baseurl'), $elem);
    });
});

_.defer(() => {
    $("[data-selector='fileset-container']").each((ix, elem) => {
        const fileset = $(elem).find("[data-selector='fileset']");
        const pendingFiles = $(elem).find("[data-selector='pending-files']");
        const noFiles = $(elem).find("[data-selector='no-files']");
        const addFiles = $(elem).find("[data-selector='add-file']");

        const files = $(fileset).data('files');
        let filesetId = $(fileset).data('filesetid');

        const id = `#${$(fileset).attr('id')}`;
        const pendingFilesId = `#${$(pendingFiles).attr('id')}`;
        const noFilesId = `#${$(noFiles).attr('id')}`;
        const addFilesId = `#${$(addFiles).attr('id')}`;

        const aanvraagFiles = filelist(
            files,
            filesetId,
            template_newfile_row,
            template_file_row,
            id,
            pendingFilesId,
            addFilesId,
            noFilesId,
        );

        if (filesetId === undefined) {
            filesetId = 'new';
        }

        $(`#dossier-filelist-form-${filesetId}`).submit(function () {
            $(this)
                .find('input:checked')
                .each((ix, elem) => {
                    const inp = $(elem).closest("[data-selector='file']");
                    const jsonStr = inp.find("input[name='File']").val();
                    const fileObj = JSON.parse(jsonStr);
                    delete fileObj.FileSetId;
                    delete fileObj.UploadedFileId;
                    if (!filesetId.startsWith('new')) {
                        aanvraagFiles.UploadedFiles.create(fileObj);
                    } else {
                        aanvraagFiles.UploadedFiles.add(fileObj);
                    }
                    inp.remove();
                });

            $(this).closest('.modal').modal('hide');

            if ($(this).find('input').length == 0) {
                $(this).find('[data-selector="no-files"]').show();
            }

            return false;
        });
    });
});

$(() => {
    $("[data-richtext='true']").each((ix, elem) => {
        tinyMCE.full($(elem));
    });
    $("[data-richtext='simple']").each((ix, elem) => {
        tinyMCE.simple($(elem));
    });

    /**
     * workaround for the tinyMCE modals inside a bootstrap modal, see also http://stackoverflow.com/questions/18111582/tinymce-4-links-plugin-modal-in-not-editable
     */
    $(document).on('focusin', (e) => {
        if ($(e.target).closest('.mce-window').length) {
            e.stopImmediatePropagation();
        }
    });

    preventLeave.initial();
});

$('input[data-search-for]').on('keypress', (e) => {
    const code = e.keyCode || e.which;
    if (code == 13) {
        e.preventDefault();
        return false;
    }
});

function performSearch(val, table) {
    val = val.split(/\s/);
    if (val === '') {
        return table.find('[data-search]').show();
    }
    const trs = table.find('[data-search]');
    for (let i = 0, len = trs.length; i < len; i++) {
        const tr = trs[i];
        const $tr = $(tr);
        if ($tr.is('[data-search]')) {
            var srch = `${$tr.data('search')}`;
            if (_.all(val, (v) => srch.toLowerCase().indexOf(v) !== -1)) {
                if (!$tr.is(':visible')) {
                    $tr.show();
                }
            } else if ($tr.is(':visible')) {
                $tr.hide();
            }
        }
    }
}

$('input[data-search-for]').on(
    'keyup',
    _.throttle(function () {
        const val = $(this).val().toLowerCase();
        const table = $(`[data-selector='${$(this).data('search-for')}']`);

        performSearch(val, table);
    }, 500),
);

$('input[data-search-for]').each(function (ix, elem) {
    const val = $(this).val().toLowerCase();
    const table = $(`[data-selector='${$(this).data('search-for')}']`);

    performSearch(val, table);
});

$.tablesorter.addParser({
    id: 'customNumber',
    is(s) {
        return /^[0-9]?[0-9,\.]*$/.test(s);
    },
    format(s) {
        return jQuery.tablesorter.formatFloat(s.replace(/,/g, ''));
    },
    type: 'numeric',
});

_.defer(() => {
    $('.tablesorter').each(function () {
        const tableHeaderConfig = {};
        const table = $(this);

        if (table.find('.table-empty').length > 0) {
            return false;
        }

        table.find('th').each((ix, elem) => {
            let s;
            s = $(elem).data('sorter');
            if (s != null) {
                return (tableHeaderConfig[ix] = {
                    sorter: s === false ? false : s,
                });
            }
        });

        table.tablesorter({
            textExtraction(node) {
                if ($(node).data('sort')) {
                    return $(node).data('sort');
                }
                return node.innerHTML;
            },
            headers: tableHeaderConfig,
        });
    });
});

toastr.options = {
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: false,
    positionClass: 'toast-top-right',
    onclick: null,
    showDuration: '400',
    hideDuration: '1000',
    timeOut: '7000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
};

_.defer(() => {
    if ($('#notification-data').length) {
        const nots = JSON.parse($('#notification-data').text());
        for (let i = 0, len = nots.length; i < len; i++) {
            const n = nots[i];
            if (n.Type === 0) {
                toastr.info(n.Text);
            }
            if (n.Type === 1) {
                toastr.warning(n.Text);
            }
            if (n.Type === 2) {
                toastr.error(n.Text);
            }
            if (n.Type === 3) {
                toastr.success(n.Text);
            }
        }
    }
});

$('[data-inline-delete]').each((ix, elem) => {
    const $elem = $(elem);

    const $dodelete = $elem.parent().find("[data-selector='delete']");
    const $canceldelete = $elem.parent().find("[data-selector='cancel-delete']");

    $dodelete.hide();
    $canceldelete.hide();

    $elem.click(() => {
        $elem.hide();

        $dodelete.show();
        $canceldelete.show();

        return false;
    });

    $canceldelete.click(() => {
        $elem.show();

        $dodelete.hide();
        $canceldelete.hide();

        return false;
    });
});

$('[data-multistep-modal]').each((ix, elem) => {
    const $elem = $(elem);

    $elem.closest('form').validate();

    function showCurrentStep() {
        const step = $elem.data('step');

        const from = $elem.find('[data-step]:visible').data('step');

        if (step == 1 && from != 2) {
            // alles behalve 1 instant hidden
            $elem.find("[data-step][data-step!='1']").hide();
            $elem.find("[data-step='1']").show();

            // 1 krijgt modal-show
            $elem.find("[data-step='1']").addClass('modal-show').removeClass('modal-hide');

            // alles behalve 1 krijgt modal-hide
            $elem.find("[data-step][data-step!='1']").addClass('modal-hide').removeClass('modal-show');
        } else {
            // step instant show
            $elem.find(`[data-step=${step}]`).show();

            if (step == from + 1) {
                $elem.find(`[data-step=${step}]`).addClass('modal-show').removeClass('modal-hide');
            } else {
                $elem.find(`[data-step][data-step!=${step}]`).addClass('modal-hide').removeClass('modal-show');
            }

            setTimeout(() => {
                $elem.find(`[data-step][data-step!=${step}]`).hide();
                $elem.find(`[data-step][data-step!=${step}]`).removeClass('modal-show');
            }, 300);
        }
    }

    $elem.on('show.bs.modal', () => {
        $elem.data('step', 1);
        showCurrentStep();
    });

    $elem.on('hide.bs.modal', () => {
        $elem.find('[data-step]:visible').addClass('modal-hide').removeClass('modal-show');
    });

    $elem.closest('form').submit(() => {
        const step = $elem.data('step');

        if (!$elem.closest('form').valid()) {
            return false;
        }

        if ($elem.find(`[data-step=${step + 1}]`).length) {
            $elem.data('step', step + 1);
            showCurrentStep();
            return false;
        }
    });

    $elem.find("[data-selector='next']").click(() => {
        if (!$elem.closest('form').valid()) {
            return false;
        }

        $elem.data('step', $elem.data('step') + 1);
        showCurrentStep();

        return false;
    });

    $elem.find("[data-selector='prev']").click(() => {
        $elem.data('step', $elem.data('step') - 1);
        showCurrentStep();

        return false;
    });
});

$('[data-multistep]').each((ix, elem) => {
    const $elem = $(elem);

    $elem.validate();

    $elem.data('step', 1);

    function showCurrentStep() {
        const step = $elem.data('step');
        $elem.find(`[data-step][data-step!=${step}]`).hide();
        $elem.find(`[data-step=${step}]`).show();
    }

    showCurrentStep();

    $elem.submit(() => {
        const step = $elem.data('step');

        if (!$elem.valid()) {
            return false;
        }

        if ($elem.find(`[data-step=${step + 1}]`).length) {
            $elem.data('step', step + 1);
            showCurrentStep();
            return false;
        }
    });

    $elem.find("[data-selector='next']").click(() => {
        if (!$elem.valid()) {
            return false;
        }

        $elem.data('step', $elem.data('step') + 1);
        showCurrentStep();

        return false;
    });

    $elem.find("[data-selector='prev']").click(() => {
        $elem.data('step', $elem.data('step') - 1);
        showCurrentStep();

        return false;
    });
});

// Console.log Fallback for Internet Explorer
if (!window.console) {
    window.console = new (function () {
        this.log = function (str) {};
        this.dir = function (str) {};
    })();
}

// collapsible text
$('p[data-collapsed]').each((ix, elem) => {
    const $elem = $(elem);
    const charCount = $elem.data('collapsed');

    if ($elem.text().length > charCount) {
        $elem.data('html', $elem.text());
    }
});

window.parseDecimal = function (str) {
    const nlParsed = parseDecimalNumber(str, '.,');
    if (isNaN(nlParsed)) {
        return parseDecimalNumber(str, ',.');
    }
    return nlParsed;
};

$('[data-toggle="tooltip"]').tooltip();

// show or hide form fields based on radio buttons
$('[data-showhide]').change(function () {
    const $this = $(this);

    if ($this.val() == 'false') {
        $($this.data('showhide')).show();
    } else {
        $($this.data('showhide')).hide();
    }
});
$('[data-showhide]:checked').change();

$('[data-hideshow]').change(function () {
    const $this = $(this);

    if ($this.val() == 'true') {
        $($this.data('hideshow')).show();
    } else {
        $($this.data('hideshow')).hide();
    }
});
$('[data-hideshow]:checked').change();

$('[data-ratings]').each(function () {
    const name = $(this).data('ratings');
    const output = $(this).find("[data-selector='ratings-output']");

    if (!name || !output) return;

    $(`[name='${name}']`).change(function () {
        if ($(this).is(':checked')) {
            output.text(`(${$(this).val() * 2} / 10)`);
        }
    });

    $(`[name='${name}']`).click(function () {
        const value = $(this).val();
        $(this)
            .parent()
            .parent()
            .find('.rating-label')
            .each((ix, elem) => {
                const elemValue = $(elem).data('val');
                if (elemValue <= value) {
                    $(elem).addClass('rating-label-checked');
                } else {
                    $(elem).removeClass('rating-label-checked');
                }
            });
    });

    $(`[name='${name}']`).change();
    $(`[name='${name}']:checked`).click();
});

$('input[data-vat]').keyup(function (ev) {
    if (ev.which == 9 || ev.which == 13) return; // tab or enter

    const me = $(this);

    if (me.data('vat') == 'with') {
        var other = me.parent().closest('[data-vat]').find("[data-vat='without']");
        var multiplier = 1.0 / 1.21;
    } else {
        var other = me.parent().closest('[data-vat]').find("[data-vat='with']");
        var multiplier = 1.21;
    }

    const myVal = window.parseDecimal(me.val());
    if (isNaN(myVal)) {
        other.val('');
    } else {
        other.val(numeral(myVal * multiplier).format('0,0.00'));
    }
});

$('[data-copy-value]').change(function () {
    const other = $($(this).data('copy-value'));
    other.val($(this).val());
});

$('select[data-toggle-input]').change(function () {
    const other = $($(this).data('toggle-input'));
    const selectedOption = $(this).find('option:selected');

    if (selectedOption.data('show-input') == true) {
        other.show();
    } else {
        other.hide();
    }
});
