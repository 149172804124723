module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if(IsMe) { 
__p+='\n	';
 if(IsDeleted) { 
__p+='\n		<div class="message-deleted">\n			- Dit bericht is verwijderd door '+
((__t=( AuthorName ))==null?'':_.escape(__t))+
' <span class="message-datetime">'+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'</span>\n			';
 if(CanEdit) { 
__p+='\n				<button class="btn btn-link" data-selector="undelete-message-button"><i class="icon-arrow-return-left"></i></button>\n			';
 } 
__p+='\n		</div>\n	';
 } else { 
__p+='\n		<div class="message-box">\n			<div class="message-container">\n				<div class="message-content">\n					<p class="inline-value pre-line">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</p>\n					<textarea rows="3" name="Text" class="form-control inline-edit">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</textarea>\n					<div class="message-actions">\n						';
 if(CanEdit) { 
__p+='\n								<button class="btn btn-link inline-value" data-selector="delete-message-button"><i class="icon-trash-b"></i></button>\n								<button class="btn btn-link inline-value" data-selector="edit-message-button"><i class="icon-edit"></i></button>\n								<button class="btn btn-link inline-edit" data-selector="save-message-button">opslaan</button>\n						';
 } 
__p+='\n						';
 if(!IsFlagged) { 
__p+='\n							<button class="btn btn-link inline-value" data-selector="flag-message-button"><i class="icon-flag"></i></button>\n						';
 } else { 
__p+='\n							<button class="btn btn-link inline-value" data-selector="unflag-message-button"><i class="icon-unflag"></i></button>\n						';
 } 
__p+='\n					</div>\n				</div>\n				<div class="message-datetime">\n					<i title="'+
((__t=( moment(Date).format('D MMM Y, HH:mm') ))==null?'':__t)+
'">'+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'</i>\n				</div>\n			</div>\n			';
 if(SmallAvatarUrl !== null) { 
__p+='\n				<div class="message-avatar">\n					<img src="'+
((__t=( SmallAvatarUrl ))==null?'':__t)+
'" width="30" height="30" />\n				</div>\n			';
 } else {
__p+='\n				<div class="message-avatar message-avatar-placeholder">\n					<img src="'+
((__t=( avatar_placeholder_svg ))==null?'':__t)+
'" alt="" class="avatar-placeholder" width="30" height="30" />\n				</div>\n			';
 } 
__p+='\n		</div>\n	';
 } 
__p+='\n';
 } else { 
__p+='\n	';
 if(IsDeleted) { 
__p+='\n		<div class="message-deleted">\n			- Bericht verwijderd door '+
((__t=( AuthorName ))==null?'':_.escape(__t))+
' '+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'\n			';
 if(CanEdit) { 
__p+='\n				<button class="btn btn-undo" data-selector="undelete-message-button"><i class="icon-arrow-return-left"></i></button>\n			';
 } 
__p+='\n		</div>\n	';
 } else { 
__p+='\n		<div class="message-box">\n			';
 if(SmallAvatarUrl !== null) { 
__p+='\n				<div class="message-avatar">\n					<img src="'+
((__t=( SmallAvatarUrl ))==null?'':__t)+
'" width="30" height="30" />\n				</div>\n			';
 } else {
__p+='\n				<div class="message-avatar message-avatar-placeholder">\n					<img src="'+
((__t=( avatar_placeholder_svg ))==null?'':__t)+
'" alt="" class="avatar-placeholder" width="30" height="30" />\n				</div>\n			';
 } 
__p+='\n\n			<div class="message-container">\n				<div class="message-content">\n					'+
((__t=( AuthorName ))==null?'':_.escape(__t))+
'\n					<p class="inline-value pre-line">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</p>\n					<textarea rows="3" name="Text" class="form-control inline-edit">'+
((__t=( Text ))==null?'':_.escape(__t))+
'</textarea>\n					<div class="message-actions">\n						';
 if(CanEdit) { 
__p+='\n								<button class="btn btn-link inline-value" data-selector="delete-message-button"><i class="icon-trash-b"></i></button>\n								<button class="btn btn-link inline-value" data-selector="edit-message-button"><i class="icon-edit"></i></button>\n								<button class="btn btn-link inline-edit" data-selector="save-message-button">opslaan</button>\n						';
 } 
__p+='\n						';
 if(!IsFlagged) { 
__p+='\n							<button class="btn btn-link inline-value" data-selector="flag-message-button"><i class="icon-flag"></i></button>\n						';
 } else { 
__p+='\n							<button class="btn btn-link inline-value" data-selector="unflag-message-button"><i class="icon-unflag"></i></button>\n						';
 } 
__p+='\n					</div>\n				</div>\n				<div class="message-datetime">\n					<i title="'+
((__t=( moment(Date).format('D MMM Y, HH:mm') ))==null?'':__t)+
'">'+
((__t=( moment(Date).fromNow() ))==null?'':_.escape(__t))+
'</i>\n				</div>\n			</div>\n		</div>\n	';
 } 
__p+='\n';
 } 
__p+='';
}
return __p;
};
