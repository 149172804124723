import 'spectrum-colorpicker';

$("input[type='color']").spectrum({
    flat: false,
    showInput: true,
    showInitial: true,
    allowEmpty: false,
    showAlpha: false,
    disabled: false,
    cancelText: 'Annuleren',
    chooseText: 'Kies kleur',
    preferredFormat: 'hex',
});
