import toastr from 'toastr';
import _ from 'underscore';
import Backbone from 'backbone';
import qq from 'fine-uploader';
import fine_uploader_tmpl from '../fine-uploader.tmpl';
import fileModalPartial from './file-modal-partial.tmpl';

export default function (
    vm,
    fileSetId,
    template_newfile_row,
    template_file_row,
    elemId,
    pendingFilesId,
    addFileId,
    noFilesId,
) {
    elemId = elemId || '#files';
    pendingFilesId = pendingFilesId || '#pending-files';
    addFileId = addFileId || '#add-file';
    noFilesId = noFilesId || '#no-files';

    if (!$('#qq-template').length) {
        const tmpldiv = $('<div/>');
        tmpldiv.attr('id', 'qq-template');
        tmpldiv.attr('style', 'display: none !important');
        tmpldiv.html(fine_uploader_tmpl());
        $('body').append(tmpldiv);
    }

    const UploadedFile = Backbone.Model.extend({
        idAttribute: 'UploadedFileId',
        defaults: {
            UploadedFileId: null,
            FileSetId: fileSetId,
            Title: 'Nieuw bestand',
            Description: '',
            DownloadUrl: null,
            ThumbnailUrl: null,
            IconUrl: null,
            PreviewUrl: null,
            Selected: false,
            Editing: false,
            Removing: false,
        },
        validate(attrs) {
            const errors = {};

            if (!attrs.Title) {
                errors.Title = 'required';
            }

            return _.isEmpty(errors) ? undefined : errors;
        },
    });

    const PendingUploadedFile = Backbone.Model.extend({
        defaults: {
            Title: 'Nieuw bestand',
            Description: '',
            DownloadUrl: null,
            ThumbnailUrl: null,
            IconUrl: null,
            PreviewUrl: null,
            Selected: false,
            Editing: false,
            Removing: false,
        },
        validate(attrs) {
            const errors = {};

            if (!attrs.Title) {
                errors.Title = 'required';
            }

            return _.isEmpty(errors) ? undefined : errors;
        },
    });

    const UploadedFileCollection = Backbone.Collection.extend({
        url: $(elemId).data('fileseturl'),
        model: UploadedFile,
    });

    const PendingUploadedFileCollection = Backbone.Collection.extend({
        model: PendingUploadedFile,
    });

    const UploadedFiles = new UploadedFileCollection();
    const PendingUploadedFiles = new PendingUploadedFileCollection();

    const PendingUploadedFileView = Backbone.View.extend({
        tagName: 'div',
        template: template_newfile_row,
        events: {
            "click [data-selector='save-file-button']": 'save',
        },
        initialize() {
            this.listenTo(this.model, 'change:Progress', this.updateProgress);
            this.listenTo(this.model, 'change:DownloadUrl', this.updateDownloadUrl);
            this.listenTo(this.model, 'remove', this.remove);
            this.listenTo(this.model, 'uploadComplete', this.uploadComplete);
        },
        render() {
            this.$el.addClass('file-container');
            this.$el.html(this.template(this.model.toJSON()));

            return this;
        },
        save() {
            const title = this.$el.find("[data-inputfor='Title']").val();
            const description = this.$el.find("[data-inputfor='Description']").val();

            this.$el.find('.error').removeClass('error');

            const res = this.model.set({
                Title: title,
                Description: description,
            });

            if (this.model.isValid()) {
                PendingUploadedFiles.remove(this.model);
                const mdlJson = this.model.toJSON();
                delete mdlJson.id;

                if (fileSetId !== undefined) {
                    UploadedFiles.create(mdlJson);
                } else {
                    UploadedFiles.add(mdlJson);
                }
                this.remove();
            } else {
                for (const k in this.model.validationError) {
                    this.$el.find(`[data-inputfor='${k}']`).addClass('error');
                }
            }
        },
        updateProgress() {
            this.$el.find('.file-progress').attr('value', this.model.get('Progress'));
        },
        uploadComplete() {
            this.$el.find('.file-progress').hide();
            this.$el.find('.inline-edit').show();
            this.save();
        },
        updateDownloadUrl() {
            this.$el.find("[data-inputfor='DownloadUrl']").val(this.model.get('DownloadUrl'));
            this.$el.find("[data-inputfor='ThumbnailUrl']").val(this.model.get('ThumbnailUrl'));
            this.$el.find("[data-inputfor='PreviewUrl']").val(this.model.get('PreviewUrl'));

            const thumbUrl = this.model.get('ThumbnailUrl');
            if (thumbUrl) {
                this.$el.find("img[data-selector='thumbnail']").attr('src', thumbUrl);
            }

            const iconUrl = this.model.get('IconUrl');
            if (iconUrl && !thumbUrl) {
                this.$el.find("img[data-selector='thumbnail']").attr('src', iconUrl);
            }
        },
    });

    const FileListAddFilesView = Backbone.View.extend({
        el: $(pendingFilesId),
        initialize() {
            this.listenTo(PendingUploadedFiles, 'add', this.addOne);
        },
        render() {
            const $add_file = $(addFileId);
            new qq.FineUploader({
                element: $add_file[0],
                multiple: true,
                disableCancelForFormUploads: true,
                messages: {
                    sizeError: '{file} is te groot, de maximale bestandsgrootte is {sizeLimit}.',
                },
                validation: {
                    sizeLimit: 1000 * 1000 * 250, // apx. 250MB
                },
                request: {
                    endpoint: $(elemId).data('file-endpoint'),
                },
                callbacks: {
                    onSubmit(id, name) {
                        const dotPos = name.lastIndexOf('.');

                        if (dotPos != -1) {
                            name = name.substring(0, dotPos);
                        }

                        PendingUploadedFiles.add({
                            id,
                            Title: name,
                        });
                    },
                    onComplete(id, name, json) {
                        const _model = PendingUploadedFiles.get(id);
                        if (_model === null) return;

                        _model.set({
                            DownloadUrl: json.url,
                            ThumbnailUrl: json.thumbUrl,
                            IconUrl: json.iconUrl,
                            PreviewUrl: json.previewUrl,
                            FileType: json.fileType,
                        });

                        _model.trigger('uploadComplete');
                    },
                    onProgress(id, name, upBytes, totBytes) {
                        const _model = PendingUploadedFiles.get(id);
                        _model.set({
                            Progress: (100.0 * upBytes) / totBytes,
                        });
                    },
                    onTotalProgress(upBytes, totBytes) {
                        $add_file.find('.total-progress').attr('value', (100.0 * upBytes) / totBytes);
                    },
                    onError(id, name, errorReason, xhr) {
                        PendingUploadedFiles.remove(id);
                        toastr.error(`Kan bestand ${name} niet uploaden`);
                    },
                },
            });
        },
        addOne(file) {
            const view = new PendingUploadedFileView({model: file});
            this.$el.append(view.render().el);
        },
    });

    const UploadedFileView = Backbone.View.extend({
        tagName: 'div',
        template: template_file_row,
        events: {
            "click [data-selector='select-file-button']": 'select',
            "click [data-selector='edit-file-button']": 'edit',
            "click [data-selector='save-file-button']": 'save',
            "click [data-selector='try-delete-file-button']": 'tryRemove',
            "click [data-selector='cancel-delete-file-button']": 'cancelRemove',
            "click [data-selector='delete-file-button']": 'clear',
        },
        initialize() {
            _.bindAll(this, 'render');

            this.listenTo(this.model, 'change', this.render);
            this.listenTo(this.model, 'destroy', this.remove);
        },
        render() {
            const vm = this.model.toJSON();
            vm.fileModalPartial = function () {
                return fileModalPartial(vm);
            };
            this.$el.html(this.template(vm));

            if(vm.Selected) {
                this.$el.addClass('selected');
            } else {
                this.$el.removeClass('selected');
            }
            if(vm.Editing) {
                this.$el.addClass('editing');
                this.$el.removeClass('viewing');
            } else {
                this.$el.addClass('viewing');
                this.$el.removeClass('editing');
            }
            this.$el.addClass('file-container');
            return this;
        },
        edit() {
            this.model.set('Editing', true);
            this.$el.find("input[type='text']").first().focus();
            return false;
        },
        select() {
            this.model.set('Selected', !this.model.get('Selected'));
            return false;
        },
        save() {
            const title = this.$el.find("[data-inputfor='Title']").val();
            const description = this.$el.find("[data-inputfor='Description']").val();

            this.$el.find('.error').removeClass('error');

            let res;
            if (fileSetId !== undefined) {
                res = this.model.save({
                    Title: title,
                    Description: description,
                });
            } else {
                res = this.model.set(
                    {
                        Title: title,
                        Description: description,
                    },
                    {validate: true},
                );
            }

            if (res !== false) {
                this.model.set('Editing', false);
                this.render();
            } else {
                for (const k in this.model.validationError) {
                    this.$el.find(`[data-inputfor='${k}']`).addClass('error');
                }
            }
            return false;
        },
        tryRemove() {
            this.model.set("Removing", true);
            return false;
        },
        cancelRemove() {
            this.model.set("Removing", false);
            return false;
        },
        clear() {
            this.model.destroy();
            return false;
        },
    });

    const createDownloadLink = (fileIds, label) => {
        let url = $(elemId).data('file-endpoint').replace("Index", "Bulk");
        let params = new URLSearchParams(url.search);
        for (let i = 0; i < fileIds.length; i++) {
            params.append("file", fileIds[i]);
        }
        let anchor = $('<a class="link-bulk-download" data-selector="download-bulk-link">...</a>');
        anchor.text(label);
        anchor.attr("href", url + "?" + params);
        return anchor;
    }

    const UploadedFileListView = Backbone.View.extend({
        el: $(elemId),
        initialize() {
            this.listenTo(UploadedFiles, 'add', this.addOne);
            this.listenTo(UploadedFiles, 'reset', this.addAll);
            this.listenTo(UploadedFiles, 'all', this.render);

            UploadedFiles.reset(vm);
        },
        render() {
            const selectedFileIds = UploadedFiles.filter(function(file) {
                return file.get("Selected")
            }).map(function(file) {
                return file.get("UploadedFileId");
            })
            const allFileIds = UploadedFiles.map(function(file) {
                return file.get("UploadedFileId");
            })

            this.$el.find('.file-bulk-buttons').remove();
            let buttonWrapper = $('<div class="file-bulk-buttons"></div>')
            if(allFileIds.length && selectedFileIds.length < allFileIds.length) {
                buttonWrapper.append(createDownloadLink(allFileIds, 'Alle bestanden downloaden'));
            }
            if(selectedFileIds.length) {
                buttonWrapper.append(createDownloadLink(selectedFileIds, 'Selectie downloaden'));
            }
            if(selectedFileIds.length || allFileIds.length) {
                this.$el.append(buttonWrapper);
            }
        },
        addOne(file) {
            const view = new UploadedFileView({model: file});
            this.$el.append(view.render().el);
        },
        addAll() {
            UploadedFiles.each(this.addOne, this);
        },
    });

    const MainFileView = new UploadedFileListView();
    const UploadFilesView = new FileListAddFilesView();
    UploadFilesView.render();

    function updateEmptyText() {
        if (UploadedFiles.any() || PendingUploadedFiles.any()) {
            $(noFilesId).hide();
        } else {
            $(noFilesId).show();
        }
    }

    UploadedFiles.on('all', updateEmptyText);
    PendingUploadedFiles.on('all', updateEmptyText);

    updateEmptyText();

    return {
        UploadedFiles,
        PendingUploadedFiles,
    };
}
