import moment from 'moment';

import _ from 'underscore';
import Backbone from 'backbone';
import template_message from './message.tmpl';
import template_message_add from './message_add.tmpl';
import avatar_placeholder_svg from '../../img/avatar-placeholder.svg';

require('jquery-validation');

moment.locale('nl');

export default function (baseurl, parentElement, myUserId) {
    const Message = Backbone.Model.extend({
        idAttribute: 'ChatMessageId',
        defaults: {
            Text: 'Nieuw bericht',
            IsEdited: false,
            IsDeleted: false,
            IsMe: false,
            IsFlagged: false,
        },
        validate(attrs) {
            const errors = {};

            if (!attrs.Text && !attrs.IsDeleted) {
                errors.Text = 'required';
            }

            return _.isEmpty(errors) ? undefined : errors;
        },
    });

    const MessageCollection = Backbone.Collection.extend({
        url: baseurl,
        model: Message,
        periodicRefresh() {
            const collection = this;
            setInterval(() => {
                collection.fetch();
            }, 10 * 1000);
        },
    });

    const Messages = new MessageCollection();

    const MessageAddView = Backbone.View.extend({
        el: parentElement.find("[data-selector='add-message-field']"),
        events: {
            "click [data-selector='submit-message-button']": 'send',
            "keydown textarea[name='Text']": 'keyDown',
            "keyup textarea[name='Text']": 'resize',
        },
        render() {
            if (!parentElement.data('read-only')) {
                this.$el.html(template_message_add());
                this.resize();
            } else {
                this.$el.empty();
            }
        },
        calcHeight() {
            const txt = this.$el.find("[name='Text']");
            const outerHeight = txt.outerHeight();
            const {scrollHeight} = txt[0];
            const innerHeight = txt.innerHeight();

            const calcHeight = scrollHeight + outerHeight - innerHeight;

            return calcHeight;
        },
        keyDown() {
            this.setOverflow();
        },
        setOverflow() {
            const txt = this.$el.find("[name='Text']");
            const calcHeight = this.calcHeight();
            if (calcHeight < 400) {
                txt.css('overflow', 'hidden');
            } else {
                txt.css('overflow', 'initial');
            }
        },
        resize() {
            const txt = this.$el.find("[name='Text']");
            const calcHeight = this.calcHeight();
            const minHeight = 90;
            const maxHeight = 400;

            const newHeight = Math.min(maxHeight, Math.max(minHeight, calcHeight));

            txt.css('height', `${newHeight}px`);
            this.setOverflow();
        },
        send() {
            const message = this.$el.find("[name='Text']").val();

            this.$el.find('.error').removeClass('error');

            const mdl = new Message({
                Text: message,
            });

            if (mdl.validate(mdl.toJSON()) !== undefined) {
                for (const k in mdl.validationError) {
                    this.$el.find(`[name='${k}']`).addClass('error');
                }
            } else {
                Messages.create(mdl.toJSON(), {wait: true});
                this.render();
                this.$el.find('textarea').focus();
            }
        },
    });

    const MessageView = Backbone.View.extend({
        tagName: 'div',
        template: template_message,
        events: {
            "click [data-selector='delete-message-button']": 'markDelete',
            "click [data-selector='undelete-message-button']": 'markUndelete',
            "click [data-selector='edit-message-button']": 'toggleEdit',
            "click [data-selector='save-message-button']": 'save',
            "click [data-selector='flag-message-button']": 'flag',
            "click [data-selector='unflag-message-button']": 'unflag',
            "keydown textarea[name='Text']": 'keyDown',
        },
        initialize() {
            _.bindAll(this, 'render');

            this.listenTo(this.model, 'change', this.render);
            this.listenTo(this.model, 'destroy', this.remove);

            this.listenTo(Messages, 'sync', this.reRender);
        },
        render() {
            const vm = this.model.toJSON();
            vm.moment = moment;
            vm.avatar_placeholder_svg = avatar_placeholder_svg;
            this.$el.html(this.template(vm));
            this.$el.addClass('viewing');
            this.$el.removeClass('editing');
            return this;
        },
        reRender() {
            if (!this.$el.hasClass('editing')) {
                this.render();
            }
        },
        keyDown(event) {
            if (event.keyCode == 13 && event.shiftKey) {
                event.preventDefault();
                this.save();
            }
        },
        markDelete() {
            this.model.save({IsDeleted: true});
        },
        markUndelete() {
            this.model.save({IsDeleted: false}, {validate: false});
        },
        flag() {
            this.model.save({IsFlagged: true});
        },
        unflag() {
            this.model.save({IsFlagged: false});
        },
        toggleEdit() {
            this.$el.toggleClass('viewing');
            this.$el.toggleClass('editing');
        },
        save() {
            const text = this.$el.find("[name='Text']").val();

            this.$el.find('.error').removeClass('error');

            const res = this.model.save({
                Text: text,
            });

            if (res !== false) {
                this.render();
            } else {
                for (const k in this.model.validationError) {
                    this.$el.find(`[data-inputfor='${k}']`).addClass('error');
                }
            }
        },
    });

    const MessageListView = Backbone.View.extend({
        el: parentElement.find("[data-selector='message-container']"),
        initialize() {
            this.listenTo(Messages, 'add', this.addOne);
            this.listenTo(Messages, 'reset', this.addAll);
        },
        addOne(file) {
            parentElement.find("[data-selector='no-messages']").hide();
            const view = new MessageView({model: file});
            this.$el.append(view.render().el);
            this.$el.animate({scrollTop: this.$el.prop('scrollHeight')}, 500);
        },
        addAll() {
            Messages.each(function (file) {
                const view = new MessageView({model: file});
                this.$el.append(view.render().el);
            }, this);

            this.$el.scrollTop(this.$el.prop('scrollHeight'));

            if (Messages.length === 0) {
                parentElement.find("[data-selector='no-messages']").show();
            } else {
                parentElement.find("[data-selector='no-messages']").hide();
            }
        },
    });

    const MainMessageView = new MessageListView();

    const AddView = new MessageAddView();
    AddView.render();

    Messages.fetch({reset: true});
}
